<template>
    <div>
        <div class="tops">
            <img src="../assets/imgs/uploads/Rectangle 62.jpg" alt="" width="100%">
            <div class="paws">
                About us
            </div>
            <div class="desc">
                <div class="gfp">We are a Hong Kong based company created back in 2010 that has a very simple mission since
                    the</div>
                <div class="gfp">first days of its establishment: to help people and their pets spend hours of fun together.
                </div>
                <div class="gfp">Our commitment to pet needs requires a holistic approach to making toys. It begins by using
                    research</div>
                <div class="gfp">to understand what sort of materials combined together work best, it continues by bringing
                    together</div>
                <div class="gfp">people to develop new ideas, and it comes back round by measuring the outcomes of our
                    products.</div>
                <div class="gfp">We gather exceptional team of professionals from all over the world with various
                    backgrounds, beliefs,</div>
                <div class="gfp">and perspectives, , but we all share one common dream: develop our products for the better
                    and make</div>
                <div class="gfp">you and your pet satisfied.</div>
            </div>
        </div>
        <div style="display: flex;justify-content: center;">
            <div style="font-size: 40px;font-weight: bold;">Our Vision, Mission, and Values</div>
        </div>
        <div class="pmv">
            <div v-for="item in our" :key="item.id" style="width: 445px;">
                <div>
                    <img v-lazy="item.imgPath" alt="" style="width: 445px;height: 226px;">
                </div>
                <div style="height: 102px; display: flex;justify-content: center;align-items: center;">
                    <div>
                        <div style="display: flex;justify-content: center;">
                            <p>{{ item.label1 }}</p>
                        </div>
                        <div style="display: flex;justify-content: center;">
                            <p>{{ item.label2 }}</p>
                        </div>
                        <div style="display: flex;justify-content: center;">
                            <p>{{ item.label3 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <img src="../assets/imgs/uploads/Design.png" alt="" style="width: 100%;margin-bottom: 115px;">
        </div>
        <div style="display: flex;justify-content: center;">
            <div style="font-size: 40px;font-weight: bold;">Why Choose Us</div>
        </div>
        <div class="wchose">
            <div class="faer">
                <img src="../assets/imgs/uploads/ph_factory.png" alt="" class="Img">
            </div>
            <div class="faer1">We own more than 10 self-owned factories in China, Vietnam, Australia, and</div>
            <div class="faer1">Canada. We can provide cost-effective products and ample productivity.</div>
            <div class="faer">
                <img src="../assets/imgs/uploads/ant-design_safety-outlined.png" alt="" class="Img">
            </div>
            <div class="faer1">We strictly control the entire process from design to shipment and choose safe</div>
            <div class="faer1">and high-performance materials.</div>
            <div class="faer">
                <img src="../assets/imgs/uploads/fluent-emoji-high-contrast_handshake.png" alt="" class="Img">
            </div>
            <div class="faer1">We stick to long-term cooperation with our partners and aim to become friends,</div>
            <div class="faer1">rather than prioritizing our own interests.</div>
            <div class="faer">
                <img src="../assets/imgs/uploads/Vector.png" alt="" class="Img">
            </div>
            <div class="faer1">We have the top design team. Most of our products are unique and protected by</div>
            <div class="faer1">patents. We continue to develop new products to lead the industry.</div>
            <div class="faer">
                <img src="../assets/imgs/uploads/partner 1.png" alt="" class="Img">
            </div>
            <div class="faer1">We can help our partners promote their products in the market. We can provide</div>
            <div class="faer1">publicity materials for our partners to post on social media. Additionally, we can</div>
            <div class="faer1">support our partners in advertising and participating in exhibitions together.</div>
            <div class="faer">
                <img src="../assets/imgs/uploads/worldwide 1.png" alt="" class="Img">
            </div>
            <div class="faer1">Our brands have been established for more than 10 years, and we have also</div>
            <div class="faer1">been involved in the pet industry for over 20 years. Our products are sold in</div>
            <div class="faer1">more than 30 countries worldwide.</div>
        </div>
        <div class="btm">
            <div>
                <p style="font-size: 24px;margin-bottom: 20px;font-weight: bold;display: flex;justify-content: center;">
                    Contact Us</p>
                <a style="text-decoration: underline;cursor: pointer;display: flex;justify-content: center;" target="_blank"
                    href="http://lotus@gigwi.com.hk">lotus@gigwi.com.hk</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            our: [
                {
                    id: 1,
                    imgPath: require('../assets/imgs/uploads/perspective.png'),
                    label1: '"Creating a Suite of Globally',
                    label2: 'Influential Pet Product Brands."',
                    label3: ''
                },
                {
                    id: 2,
                    imgPath: require('../assets/imgs/uploads/mission.png'),
                    label1: '"Enhancing the Lives of Pets and People',
                    label2: 'with Aesthetic Product Design."',
                    label3: ''
                },
                {
                    id: 3,
                    imgPath: require('../assets/imgs/uploads/values.png'),
                    label1: '"Prioritizing User-Centric Products as ',
                    label2: 'Supreme, Embracing Innovation as the Key',
                    label3: 'to Industry Leadership and Social Benefit."'
                },
            ]
        }
    },
    methods: {

    }
}
</script>

<style>
.tops {
    position: relative;
    margin-bottom: 310px;
}

.paws {
    position: absolute;
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.desc {
    position: absolute;
    width: 1190px;
    height: 318px;
    padding-top: 75px;
    background-color: #333;
    left: 50%;
    bottom: -210px;
    transform: translateX(-50%);
}

.pmv {
    width: 1340px;
    height: 340px;
    margin: 67px auto 100px;
    display: flex;
    justify-content: space-between;
}

.wchose {
    width: 506px;
    margin: 0 auto;
}

.faer {
    margin: 36px 0 10px;
    display: flex;
    justify-content: center;
}

.faer1 {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.gfp {
    display: flex;
    justify-content: center;
    color: #FFF;
}

.Img {
    width: 34px;
    height: 34px;
}

.btm {
    width: 767px;
    height: 195px;
    margin: 143px auto 64px;
    padding-top: 60px;
    border-radius: 20px;
    background-color: #FFF4ED;
    align-items: center;
}
</style>